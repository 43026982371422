*{
    padding:0;
    margin:0;
}

html{
    height: 100%;
}

body{
    
    height: 100%;
}

table{
    position:relative;
    height: 100%;
}

tbody{
    top:70px;
    border:1px solid blue;
}

tr{
    border:1px solid green;
}


#app{
    height: 100vh;
}

#main-content{
    display:flex;
    flex-direction: column;
}

#route-area{
    position: relative;
    flex:1;
}

.route-content{
    display : flex;
    flex-direction : column;
    height : 100%;
}

.extend{
    flex:1;
    position:relative;
}

.contratos{
    display: flex;
}

.info-bar{
    display:flex;
    justify-content: space-evenly;
}

.info-bar-title{
    font-size: 14px !important;
    padding: 5px !important;
}

.info-bar-bold{
    font-weight: bold;
}

.tab-pane{
    position: absolute !important;
    top:30 !important;
    right:0 !important;
    bottom:0 !important;
    left:0 !important;
}

.scroll-content{ /* necessário setar uma propriedade top diretamente no elemento que usar essa classe, caso seja maior que zero */
    position:absolute;
    overflow-y: auto;
    left:0px;
    right:0px;
    bottom:0px;
}

.scroll-list{ /* necessário setar uma propriedade top diretamente no elemento que usar essa classe, caso seja maior que zero */
    position:absolute;
    overflow-y: auto;
    left:0px;
    right:0px;
    bottom:0px;
    border-top: 1px solid #DEDEDF;
    border-bottom: 1px solid #DEDEDF;
}

.scroll-data-table{
    position:absolute;
    overflow-y: scroll;
    left:14px;
    right:-500px !important;
    bottom:0px;
    border:1px solid red;
}

.table-area{
    height:100%;
    display:flex;
    flex-direction:column;
}

.table-options-area{
    padding-bottom:15px;
}

.login-main{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #DEDEDF;
}

.login-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}