body{
    margin:0 !important;
    padding:0;
}

iframe{
    width: 100vw;
    height: 100vh;
}

.mensagem-erro{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}